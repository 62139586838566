<template>
  <div>
    <app-bar
      title="Daftar KKP"
      :noDivider="true"
      :previousLink="{ name: 'ProfilOrder' }" />
      <div class="row">
          <div class="col-md-7">
              <div
                v-for="kkp in listKkp"
                :key="kkp.kkp"
                class="card-info d-flex flex-column px-3 pb-3">
                  <div class="group-item d-flex flex-row py-3">
                    <img
                      class="px-2"
                      :src="`https://api.qrserver.com/v1/create-qr-code/?color=007A98&data=${kkp.kkp}`"
                      style="width: 6rem; flex: none">
                    <div class="d-flex flex-column ps-1 py-1 justify-content-between">
                      <p class="text-title-active fm-poppins fst-normal fw-500 fs-16 lh-14 mb-0 pt-1 pb-3 pe-2">
                        {{ kkp.nama }}
                      </p>
                      <div
                        v-if="kkp.status"
                        class="badge bg-success-disable text-success py-2"
                        style="border-radius: 8px; width: fit-content">
                          Selesai
                      </div>
                      <div
                        v-else
                        class="badge bg-warning-disable text-warning py-2"
                        style="border-radius: 8px; width: fit-content">
                          Belum Selesai
                      </div>
                    </div>
                  </div>
                  <div
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#modalQrCode"
                    class="btn btn-next bg-primary w-100 pt-2 pb-2">
                      <p class="text-white fm-nunito fst-normal fw-600 fs-14 lh-19 m-0">Lihat QR Code</p>
                  </div>
                  <!-- Modal QR Code -->
                  <div class="modal fade" id="modalQrCode" tabindex="-1" aria-labelledby="modalQrCode" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                              <div class="modal-body d-flex flex-column text-center px-3">
                                <div class="img-qrcode p-3">
                                  <img
                                    class="px-2 pb-2"
                                    :src="`https://api.qrserver.com/v1/create-qr-code/?color=007A98&data=${kkp.kkp}`"
                                    style="width: 100%; height: auto"
                                  >
                                </div>
                                <button type="button" class="btn btn-primary py-2" data-bs-dismiss="modal">Tutup</button>
                              </div>
                              </div>
                          </div>
                      </div>
                  <!-- Modal -->
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { getListKkp, listKkp } from '@/core/profile'
import { onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import AppBar from '@/components/AppBar.vue'

export default {
  components: { AppBar },
  setup () {
    const route = useRoute()

    onMounted(() => {
      getListKkp({
        kode: route.params.id_order
      })
    })

    return {
      listKkp
    }
  }
}
</script>

<style lang="scss" scoped>
.card-info {
    border: 1px solid #EFF0F7;
    box-sizing: border-box;
    border-radius: 10px;
}

.card-order {
    border: 1px solid #EEEFF7;
    box-sizing: border-box;
    border-radius: 10px;
}

.location-info .card-info {
    background: #F7F7FC;
}

.icon-bg {
    width: 2.5rem;
    border-radius: 8px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.btn-complete-payment {
  width: 100%;

  @media (min-width: 520px) {
    width: 65%;
    border-radius: 10px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgb(240, 240, 240);
  }
}

.card.summary-order {
  border: 1px solid #EEEFF7;
  box-sizing: border-box;
  border-radius: 10px;
}

.page-title {
   @media (max-width: 520px) {
    padding-top: 0.75rem;
  }
}
</style>
